.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #747775;
  border-radius: 4px;
  background-color: white;
  color: #1f1f1f;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.google-button:hover {
  background-color: #f8f8f8;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.google-button-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.google-button-text {
  flex-grow: 1;
  text-align: center;
}