body, html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  background-color: black;
}

#root {
  height: 100%;
  width: 100%;
}

.app-wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this new class for the blur effect */
.blur-background {
  filter: blur(5px);
}

/* Add these styles at the end of your App.css file */

.neighborhood-content {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.neighborhood-content.expanded {
  max-height: 10000px; /* A large value to accommodate all content */
  opacity: 1;
}
/* Add this new rule at the end of your existing App.css file */
.mapboxgl-marker:hover {
  z-index: 10000 !important;
}

/* Styles for map markers and property tiles */
.property-item {
  transition: all 0.3s ease;
}

.property-item:hover,
.mapboxgl-marker:hover {
  z-index: 10000 !important;
  transform: scale(1.05);
}

/* Specific style for hovered state */
.property-item-hovered {
  z-index: 10000 !important;
  transform: scale(1.05);
}

/* ... (keep existing styles) */

.map-marker {
  z-index: 1;
  transition: z-index 0s, transform 0.3s ease, background-color 0.3s ease;
}

.map-marker.hovered {
  z-index: 10000;
}

/* ... (keep existing styles) */

.mapboxgl-marker:hover,
.mapboxgl-marker.hovered {
  z-index: 10000 !important;
  background-color: #dcb482 !important; /* This is the yellow/beige color */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 767px) {
  .search-form-mobile {
    will-change: width, transform;
  }
}

/* Add this at the end of your App.css file */
input:focus {
  outline: none;
}

/* Optional: If you want to add a custom focus style */
input:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}