.video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; /* Reduced height */
  background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  pointer-events: none;
}

.video-hero-container {
  margin-bottom: 20px; /* Add some space below the video hero */
}